import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Callback: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Function to exchange the authorization code for a JWT
    const exchangeAuthCodeForToken = async (code: string) => {
        try {
            const response = await axios.post(
                'https://idp.blacktine.com/token',
                new URLSearchParams({
                    grant_type: 'authorization_code',
                    code,
                    redirect_uri: 'https://idp.blacktine.com/callback', // Must match the registered redirect URI
                    client_id: 'YOUR_CLIENT_ID',
                    client_secret: 'YOUR_CLIENT_SECRET', // If confidential client
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                },
            );

            const { access_token } = response.data;

            // Store token securely (preferably in memory or secure cookie)
            sessionStorage.setItem('access_token', access_token);

            // Redirect to the home page or protected area
            navigate('/');
        } catch (error) {
            console.error('Error exchanging authorization code:', error);
            // Handle error (e.g., display a message to the user)
        }
    };

    useEffect(() => {
        // Extract the authorization code from the query parameters
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('authCode');

        if (code) {
            exchangeAuthCodeForToken(code);
        } else {
            console.error('Authorization code not found');
            // Optionally redirect to an error page or back to login
        }
    }, [location]);

    return (
        <div>
            <h2>Processing your login...</h2>
        </div>
    );
};

export default Callback;