import { XMarkIcon, PencilIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import SourceFeed from '../../../models/SourceFeed';
import FormatType, { getFormatTypeString } from '../../../models/FormatType';
import Config from '../../../app-config.json';
import { getRegionTypeString } from '../../../models/RegionType';
import { getLanguageTypeString } from '../../../models/LanguageType';
import LoadingSpinner from '../../Controls/LoadingIcons/LoadingSpinner';
import User from '../../../models/User';
import axios from 'axios';
import apiClient from '../../../axiosConfig';

let emptyFeed: SourceFeed = new SourceFeed([]);
type props = {
  refreshData: boolean;
  id: number;
};

export default function SourceFeedDetailsReadOnlyDrawer({ refreshData, id }: props) {
  const [sourceFeed, setSourceFeed] = useState<SourceFeed>(emptyFeed);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getSourceFeed(id);
  }, [refreshData, id]); // Trigger effect when 'open' state changes

  function getSourceFeed(orgId: number = 1) {
    apiClient
      .get(`/sourcefeed/${orgId}?categories=true`)
      .then((response) => {
        const json1 = response.data; // Access the data property
        const feed: SourceFeed = {
          ...json1,
          //Manually convert the date fields into Date objects
          dateCreated: json1.dateCreated ? new Date(json1.dateCreated) : null,
          lastSyncTime: json1.lastSyncTime ? new Date(json1.lastSyncTime) : null,
          lastUpdated: json1.lastUpdated ? new Date(json1.lastUpdated) : null,
          crawlWindowMin: json1.crawlWindowMin ? new Date(json1.crawlWindowMin) : null,
          crawlWindowMax: json1.crawlWindowMax ? new Date(json1.crawlWindowMax) : null,
        };

        feed.categories.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        //fetch the username of the author after extracting authorID from sourceFeed
        apiClient
          .get('/organization/' + feed.organizationID + '/user/' + feed.authorID)
          .then((response2) => {
            const json2 = response2.data;
            feed.author = new User(json2);
            feed.organizationID = response2.data.organizationID;

            //fetch the name of the organization the sourcefeed was created under by organizationID
            apiClient
              .get('/organization/' + feed.organizationID + '?includeUsers=false')
              .then((response3) => {
                setSourceFeed({ ...feed, organizationName: response3.data.organizationName });
                setLoading(false); // Set loading to false when all data is fetched
              })
              .catch((error) => {
                console.error("Error fetching feed's organization:", error);
                feed.organizationName = 'Fetch Error: Org';
              });
          })
          .catch((error) => {
            console.error('Error fetching author details:', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching source feed:', error);
      });
  }

  return (
    <div className=" h-full">
      {/*LoadingSpinner takes place of content when data is being fetched*/}
      {loading ? ( // Render loading spinner if data is loading
        <div className="flex items-center justify-center h-full">
          <LoadingSpinner size="h-12" />
        </div>
      ) : (
        <div className="px-4 sm:gap-4 sm:px-0">
          <div
            className="h-full pb-8 pt-4 "
            aria-hidden="true"
          >
            {/*START - SourceFeed details content */}

            <div className="mb-4">
              <label
                htmlFor="email"
                className="block sm:text-lg text-md font-bold pt-2  leading-6 text-foreground"
              >
                Name
              </label>
              {/* For Name, text is one size bigger than the rest of the pulled data details */}
              <div className="  sm:text-lg text-md pt-2 leading-6 text-foreground">{sourceFeed?.name}</div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="url"
                className="block sm:text-lg text-md font-bold pt-2   leading-6 text-foreground"
              >
                Domain
              </label>
              <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">{sourceFeed?.sourceDomain}</div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block sm:text-lg text-md font-bold  leading-6 text-foreground"
              >
                Url
              </label>
              <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">{sourceFeed?.locationURL}</div>
            </div>
            <div>
              <label
                htmlFor="formatType"
                className=" sm:text-lg text-md font-bold leading-6 text-foreground"
              >
                Format Type
              </label>
              <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                {getFormatTypeString(sourceFeed?.formatTypeID)}
              </div>
            </div>
          </div>

          <div className="py-8 border-t border-tertiary">
            <div className="grid grid-cols-2">
              <div>
                <label
                  htmlFor="regionType"
                  className="sm:text-lg text-md font-bold leading-6 text-foreground"
                >
                  Region Type
                </label>
                <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                  {getRegionTypeString(sourceFeed?.regionTypeID)}
                </div>
              </div>
              <div>
                <label
                  htmlFor="regionType"
                  className="sm:text-lg text-md font-bold leading-6 text-foreground"
                >
                  Location
                </label>
                <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">TO DO</div>
              </div>
              <div className="pt-6">
                <label
                  htmlFor="formatType"
                  className="sm:text-lg text-md font-bold leading-6 text-foreground"
                >
                  Language
                </label>
                <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                  {' '}
                  {getLanguageTypeString(sourceFeed?.languageCodeID)}
                </div>
              </div>
            </div>
          </div>

          <div className="py-8  border-t border-tertiary">
            <div>
              <div>
                <div className="sm:text-lg text-md font-bold leading-6 text-foreground">Last Sync Time</div>
                <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                  {sourceFeed.lastSyncTime.toLocaleString()}
                </div>
              </div>
              <div className="grid grid-cols-2 ">
                <div>
                  <div className="pt-6">
                    <label className="sm:text-lg text-md font-bold leading-6 text-foreground">Crawl Win Min</label>
                    <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                      {' '}
                      {sourceFeed?.crawlWindowMin
                        ? sourceFeed.crawlWindowMin.toLocaleTimeString([], {
                            hour12: true,
                            hour: 'numeric',
                            minute: '2-digit',
                          })
                        : 'None'}
                    </div>
                  </div>
                  <div className="pt-6">
                    <label className="sm:text-lg text-md font-bold leading-6 text-foreground">Crawl Interval</label>
                    <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                      {sourceFeed?.crawlInterval}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="pt-6">
                    <label className="sm:text-lg text-md font-bold leading-6 text-foreground">Crawl Win Max</label>
                    <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                      {sourceFeed?.crawlWindowMax
                        ? sourceFeed.crawlWindowMax.toLocaleTimeString([], {
                            hour12: true,
                            hour: 'numeric',
                            minute: '2-digit',
                          })
                        : 'None'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Categories START */}
          <div className="px-4 py-8  sm:gap-4 sm:px-0 border-t border-tertiary">
            <div className="sm:text-lg text-md font-bold leading-6 text-foreground">Categories</div>
            <div className="flex-wrap flex-grow pt-1">
              {sourceFeed?.categories.map((item) => (
                <span className=" sm:text-base text-sm mr-1 my-1 inline-flex items-center rounded-md bg-secondary/50 px-2 py-1 font-medium text-foreground ring-1 ring-inset ring-tertiary">
                  {item.name}
                </span>
              ))}
            </div>
          </div>
          {/* Categories END */}

          <div className="py-8 border-t border-tertiary">
            <div className="grid grid-cols-2">
              <div>
                <div className="sm:text-lg text-md font-bold leading-6 text-foreground">Updated</div>
                <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                  {' '}
                  {sourceFeed.lastUpdated
                    .toLocaleString(undefined, {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true, // Use 12-hour format
                    })
                    .replace(',', ', ')}
                </div>
                <div className="pt-4 sm:text-lg text-md font-bold leading-6 text-foreground">Author</div>
                <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                  {sourceFeed.author?.firstName || 'Unknown'} {sourceFeed.author?.lastName || 'Unknown'}
                </div>
              </div>
              <div>
                <div className="sm:text-lg text-md font-bold leading-6 text-foreground">Created</div>
                <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                  {' '}
                  {sourceFeed.dateCreated
                    .toLocaleString(undefined, {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true, // Use 12-hour format
                    })
                    .replace(',', ', ')}
                </div>
                <div className="pt-4 sm:text-lg text-md font-bold leading-6 text-foreground">Organization</div>
                <div className="pt-2 sm:text-base text-sm leading-6 text-foreground">
                  {' '}
                  {sourceFeed?.organizationName}
                </div>
              </div>
            </div>
          </div>

          {/*End - SourceFeed details content */}
        </div>
      )}
    </div>
  );
}
