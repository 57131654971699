import React, { createContext, useContext, useState, useEffect } from 'react';

interface AuthContextType {
  isAuthenticated: boolean;
  accessToken: string | null;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(sessionStorage.getItem('access_token'));
  const isAuthenticated = Boolean(accessToken);

  const login = (token: string) => {
    setAccessToken(token);
    sessionStorage.setItem('access_token', token);
  };

  const logout = () => {
    console.log('Logging out');
    setAccessToken(null);
    sessionStorage.removeItem('access_token');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, accessToken, login, logout }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
