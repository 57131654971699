import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';

import {
  Bars3Icon,
  BellIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  RssIcon,
  WindowIcon,
  UsersIcon,
  BuildingOfficeIcon,
  XMarkIcon,
  ArrowLeftStartOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import ToggleButton from '../Controls/ToggleButton/ToggleButton';
import useDarkModeStore from '../../stores/useDarkModeStore';
import PopError from '../Warnings/PopError';

const navigation = [
  { name: 'Dashboard', href: '/', icon: WindowIcon, current: false },
  {
    name: 'Organization',
    href: '/styling',
    icon: BuildingOfficeIcon,
    current: false,
  },
  { name: 'Contacts', href: '#', icon: UsersIcon, current: false },
  {
    name: 'Sourcefeeds',
    href: '/toggle-button',
    icon: RssIcon,
    current: false,
  },
  { name: 'Reports', href: '#', icon: ChartPieIcon, current: false },
];

const userNavigation = [
  { name: 'Settings', icon: Cog6ToothIcon, href: '/settings/account' },
  { name: 'Sign out', icon: ArrowLeftStartOnRectangleIcon, href: '../signout' },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function Copyright() {
  return (
    <p className="text-center whitespace-nowrap text-foreground text-xs">
      {`Copyright © BlackTine LLC ${new Date().getFullYear()}.`}
    </p>
  );
}

export default function AppShell({ children }: { children: React.ReactNode }) {
  const { darkMode } = useDarkModeStore() as { darkMode: boolean };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();

  // Update the current property of the navigation array based on the current pathname
  const updatedNavigation = navigation.map((item) => ({
    ...item,
    current: item.href === location.pathname,
  }));

  return (
    <div id="app-shell">
      <div>
        <Transition.Root
          show={sidebarOpen}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4 bg-shell">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-12 w-auto"
                        src="/tempLogo.png"
                        alt="Logo"
                      />
                      <article className="prose prose-2xl ml-2 text-foreground font-bold">IceBreaker</article>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul
                        role="list"
                        className="flex flex-1 flex-col gap-y-7"
                      >
                        <li>
                          <ul
                            role="list"
                            className="-mx-2 space-y-1"
                          >
                            {updatedNavigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.current ? 'bg-tertiary text-foreground' : 'text-foreground hover:bg-secondary',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? 'text-foreground' : 'text-foreground group-hover:text-foreground',
                                      'h-6 w-6 shrink-0',
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                      <div className="mt-auto">{Copyright()}</div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-shell px-6 pb-4 shadow-lg">
            <div className="flex h-16 shrink-0 items-center pt-2">
              <img
                className="h-14 w-auto"
                src="/tempLogo.png"
                alt="Logo"
              />
              <article className="prose text-3xl ml-2 text-foreground font-bold">IceBreaker</article>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul
                role="list"
                className="flex flex-1 flex-col gap-y-7"
              >
                <li>
                  <ul
                    role="list"
                    className="-mx-2 space-y-1"
                  >
                    {updatedNavigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current ? 'bg-secondary text-foreground' : 'text-foreground hover:bg-secondary',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          )}
                        >
                          <item.icon
                            className="text-foreground h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <div className="mt-auto">{Copyright()}</div>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="flex flex-col h-screen">
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 bg-shell px-4 shadow-md sm:gap-x-6 sm:px-6 lg:px-8">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-foreground lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon
                  className="h-6 w-6"
                  aria-hidden="true"
                />
              </button>

              {/* Separator */}
              <div
                className="h-6 w-px bg-gray-900/10 lg:hidden"
                aria-hidden="true"
              />

              <div
                className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6"
                id="app-shell-top-banner"
              >
                <form
                  className="relative flex justify-center mx-auto xl:w-2/5"
                  action="#"
                  method="GET"
                >
                  <label
                    htmlFor="search-field"
                    className="sr-only"
                  >
                    Search
                  </label>
                  <MagnifyingGlassIcon
                    className={`icon pointer-events-none absolute inset-y-0 left-2.5 h-full w-4 text-${darkMode ? 'background' : 'foreground'} rounded-md`}
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className={`block self-center h-1/2 w-full border-0 py-0 pl-8 pr-0 focus:ring-0 focus:outline-none sm:text-sm rounded-md
                  ${darkMode ? 'bg-foreground text-background placeholder:text-background' : 'bg-background text-foreground placeholder:text-foreground'}`}
                    placeholder="Search..."
                    type="search"
                    name="search"
                  />
                </form>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <div className="mt-1">
                    <ToggleButton />
                  </div>
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon
                      className="h-6 w-6 text-foreground"
                      aria-hidden="true"
                    />
                  </button>

                  {/* Separator */}
                  <div
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-foreground"
                    aria-hidden="true"
                  />

                  {/* Profile dropdown */}
                  <Menu
                    as="div"
                    className="relative"
                  >
                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                      <span className="sr-only">Open user menu</span>
                      <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-foreground">
                        <span className="text-l font-medium leading-none text-background">JD</span>
                      </span>
                      <span className="hidden lg:flex lg:items-center">
                        <span
                          className="ml-4 text-sm font-semibold leading-6 text-foreground"
                          aria-hidden="true"
                        >
                          <span>John Doe</span>
                        </span>
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 text-foreground"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2.5 w-28 origin-top-right rounded-md bg-secondary py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-tertiary' : '',
                                  'block px-3 py-1 text-sm leading-6 text-foreground',
                                )}
                              >
                                <div className="flex items-center">
                                  <item.icon
                                    className="h-5 w-5 mr-2"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <div className="flex-grow overflow-y-auto">
              <main className="py-10 bg-background">
                <div className="px-4 sm:px-6 lg:px-8">{children}</div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
