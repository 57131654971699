import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import Config from '../app-config.json';

const SignOut: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Call the logout function from AuthContext
    logout();

    // Redirect to the login or home page after logging out
    window.location.href = Config.IDPUrl;
  }, [logout, navigate]);

  return (
    <div>
      <h2>Signing Out...</h2>
    </div>
  );
};

export default SignOut;
