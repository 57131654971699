import React, { FC } from 'react';


interface LoginProps {}

const LoginView: FC<LoginProps> = () => (
  <div>
    Dashboard Component
  </div>
);

export default LoginView;
