import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://api.blacktine.com/api/v1',
    //baseURL: 'http://localhost:5134/api/v1',
});

// Attach the token to every request if it exists
apiClient.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('access_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

export default apiClient;