import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// New Components
import useDarkModeStore from './stores/useDarkModeStore';
import Styling from './views/Styling/Styling';
import AppShell from './components/AppShell/AppShell';
import StatCard from './components/Dashboard/StatCard';
import Account from './components/Settings/Account';
import Notifications from './components/Settings/Notifications';
import Callback from './callback';

// New Views
import Settings from './views/Settings/Settings';
import Security from './components/Settings/Security';
import SearchBar from './components/SearchBar/SearchBar';
import ToggleButton from './components/Controls/ToggleButton/ToggleButton';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import SourceFeedDetailsDrawer from './components/Drawers/SourceFeedDetailsDrawer/SourceFeedDetailsParentDrawer';
import SourceFeedDetailsDrawerEdit from './components/Drawers/SourceFeedDetailsDrawer/SourceFeedDetailsEditDrawer';
import BigListView from './components/List/BigListView/BigListView';
import SourceFeed from './models/SourceFeed';
import { RssIcon } from '@heroicons/react/24/outline';
import BigListViewParentTest from './components/List/BigListView/BigListViewParentTest';
import LoadingSpinner from './components/Controls/LoadingIcons/LoadingSpinner';
import CreateNewSourceFeedDialogue from './views/CreateNew/CreateNewSourceFeedDialog';
import BaseListViewSourceFeedParentTest from './components/List/BaseListView/BaseListViewSourceFeedParentTest';
import BaseListViewUserParentTest from './components/List/BaseListView/BaseListViewUserParentTest';

// Legacy Views
import Home from './views/Home/Home';
import LogIn from './components/Legacy/LogIn/LogIn';
import Register from './components/Legacy/Register/Register';
import LegacyAppShell from './components/Legacy/Navigation/AppShell';
import ResponsiveAppBar from './components/Legacy/Navigation/ResponsiveAppBar';
import Sidebar from './components/Legacy/Navigation/Sidebar';
import SourceFeedsListView from './components/Legacy/SourceFeed/List/SourceFeedsListView';
import UserProfile from './components/Legacy/UserProfile/UserProfile';
import UsersListView from './components/Legacy/Users/List/UsersListView';
import LegacyHome from './components/Legacy/LegacyHome/Home';
import Registration from './views/Registration/Registration';
import Login from './views/Login/Login';
import Particles from './components/Other/Particles';
import { useAuth } from './auth/authContext';
import Config from './app-config.json';
import SignOff from './auth/signOut';

function App() {
  const { darkMode, setDarkMode } = useDarkModeStore() as {
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
  };

  const auth = useAuth();

  useEffect(() => {
    // Are we logged in and authorized?
    if (auth.isAuthenticated) {
      console.log('Authenticated!');
    } else {
      console.log('Not Authorized!');

      const url = new URL(window.location.href);
      console.log(url.pathname);
      // make sure we aren't on the auth callback
      if (url.pathname !== '/callback') window.location.href = Config.IDPUrl;
    }

    // Get the initial darkMode state from localStorage
    const initialDarkMode = localStorage.getItem('darkMode') === 'true';

    // Set the initial darkMode state
    setDarkMode(initialDarkMode);
  }, [auth]);

  return (
    <div className="overflow-x-hidden bg-background h-screen w-screen">
      <Router>
        <Routes>
          {/* New Routes */}
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/styling"
            element={
              <AppShell>
                <Styling />
              </AppShell>
            }
          />
          <Route
            path="base-list-view-source-feeds"
            element={
              <AppShell>
                <BaseListViewSourceFeedParentTest />
              </AppShell>
            }
          />
          <Route
            path="base-list-view-users"
            element={
              <AppShell>
                <BaseListViewUserParentTest />
              </AppShell>
            }
          />
          <Route
            path="create-new-source-feed"
            element={
              <AppShell>
                <CreateNewSourceFeedDialogue onClose={() => {}} />
              </AppShell>
            }
          />
          <Route
            path="/app-shell"
            element={<AppShell> </AppShell>}
          />
          <Route
            path="/toggle-button"
            element={
              <AppShell>
                <ToggleButton />
              </AppShell>
            }
          />
          <Route
            path="/stat-card"
            element={
              <AppShell>
                <StatCard />
              </AppShell>
            }
          />
          /*{' '}
          <Route
            path="/search-bar"
            element={
              <AppShell>
                <SearchBar
                  searchIcon={<MagnifyingGlassIcon />}
                  searchBarPlaceholder="Search source feeds..."
                  apiURL="/sourcefeed/?incDisabled=true"
                  searchFields={['name', 'sourceDomain', 'locationURL']}
                />
              </AppShell>
            }
          />
          <Route
            path="/settings/*"
            element={
              <AppShell>
                <Settings />
              </AppShell>
            }
          >
            <Route
              index
              element={
                <Account
                  userId="00000000-faab-0000-fefe-000000480085"
                  orgId={'1'}
                />
              }
            />
            <Route
              path="account"
              element={
                <Account
                  userId="00000000-faab-0000-fefe-000000480085"
                  orgId={'1'}
                />
              }
            />
            <Route
              path="notifications"
              element={<Notifications />}
            />
            <Route
              path="security"
              element={
                <Security
                  userId="00000000-faab-0000-fefe-000000480085"
                  orgId={'1'}
                />
              }
            />
            {/* Add more routes as needed */}
          </Route>
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/callback"
            element={<Callback />}
          />
          <Route
            path="/signout"
            element={<SignOff />}
          />
          <Route
            path="/registration"
            element={<Registration />}
          />
          <Route
            path="/loading-spinner"
            element={<LoadingSpinner />}
          />
          <Route
            path="/particles"
            element={<Particles />}
          />
          {/* Legacy Routes */}
          <Route
            path="/Legacy"
            element={<LegacyHome />}
          />
          <Route
            path="/Legacy/login"
            element={<LogIn />}
          />
          <Route
            path="/Legacy/register"
            element={<Register />}
          />
          <Route
            path="/Legacy/users/list/userslistview"
            element={<UsersListView />}
          />
          <Route
            path="/Legacy/SourceFeed/List/SourceFeedsListView"
            element={<SourceFeedsListView />}
          />
          <Route
            path="/Legacy/UserProfile/UserProfile"
            element={<UserProfile />}
          />
          <Route
            path="/Legacy/Navigation/AppShell"
            element={
              <LegacyAppShell>
                <SourceFeedsListView />
              </LegacyAppShell>
            }
          />
          <Route
            path="/Legacy/Navigation/SideBar"
            element={
              <Sidebar
                open={true}
                onCollapse={() => {}}
                onExpand={() => {}}
              />
            }
          />
          <Route
            path="/Legacy/Navigation/ResponsiveAppBar"
            element={<ResponsiveAppBar />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
