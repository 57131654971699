import { XMarkIcon, PencilIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import SourceFeed from '../../../models/SourceFeed';
import { getFormatTypeString } from '../../../models/FormatType';
import Config from '../../../app-config.json';
import MultiselectList from '../../Controls/MultiselectList/MultiselectList';
import Category from '../../../models/Category';
import LoadingSpinner from '../../Controls/LoadingIcons/LoadingSpinner';
import User from '../../../models/User';
import axios from 'axios';
import apiClient from '../../../axiosConfig';

let emptyFeed: SourceFeed = new SourceFeed([]);

type Props = {
  refreshData: boolean;
  id: number;
  setPendingChanges: (editedFeed: SourceFeed) => void;
};

export default function SourceFeedDetailsEditDrawer({ refreshData, id, setPendingChanges }: Props) {
  const [dbCategories, setDbCategories] = useState<Category[]>([]);
  const [sourceFeed, setSourceFeed] = useState<SourceFeed>(emptyFeed);
  const [loading, setLoading] = useState(true);

  const handleInputChange = (updatedFeed: SourceFeed) => {
    setPendingChanges(updatedFeed);
  };

  useEffect(() => {
    setPendingChanges(emptyFeed);
    setLoading(true);
    //fetch ALL categories from DB to reference

    apiClient
      .get(`/sourcefeed/categories`)
      .then((response) => {
        let tempList: Category[] = response.data;
        tempList.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        setDbCategories(tempList);
      })
      .catch((error: any) => {
        console.error('Error fetching categories:', error);
      });

    //fetch data for source feed by ID
    populateSourceFeed(id);
  }, [refreshData]);

  function populateSourceFeed(orgId: number = 1) {
    apiClient(`/sourcefeed/${orgId}?categories=true`)
      .then((response) => {
        const json1 = response.data; // Access the data property
        const feed: SourceFeed = json1;
        feed.authorID = json1.authorID; // Extract authorID from the first fetch response
        feed.formatTypeID = json1.formatTypeID;
        feed.dateCreated = new Date(json1.dateCreated);
        feed.lastSyncTime = new Date(json1.lastSyncTime);
        feed.lastUpdated = new Date(json1.lastUpdated);
        feed.crawlWindowMin = new Date(json1.crawlWindowMin);

        feed.crawlWindowMax = new Date(json1.crawlWindowMax);
        feed.categories.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        //fetch the username of the author after extracting authorID from sourceFeed
        axios
          .get(Config.apiRootURL + '/organization/' + feed.organizationID + '/user/' + feed.authorID)
          .then((response) => {
            const json2 = response.data;
            feed.author = new User(json2);
            feed.organizationID = json2.organizationID;

            //fetch the name of the organization the sourcefeed was created under by organizationID
            axios
              .get(Config.apiRootURL + '/organization/' + feed.organizationID + '?includeUsers=false')
              .then((response) => {
                const json3 = response.data;
                setSourceFeed({ ...feed, organizationName: json3.organizationName });
                console.log('Edit loading finished');
                setLoading(false); // Set loading to false when all data is fetched
              })
              .catch((error) => {
                console.error("Error fetching feed's organization:", error);
                feed.organizationName = 'Fetch Error: Org';
              });
          })
          .catch((error) => {
            console.error('Error fetching author details:', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching source feed:', error);
      });
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, name: event.target.value };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  }

  function handlesourceDomainChange(event: ChangeEvent<HTMLInputElement>): void {
    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, sourceDomain: event.target.value };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  }

  function handlelocationURLChange(event: ChangeEvent<HTMLInputElement>): void {
    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, locationURL: event.target.value };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  }

  //Updates the source feed's list of categories via new list passed to it from the MultiSelect component after sorting them alphabetically.
  const handleSelectionChange = (updatedSelection: Category[]) => {
    sourceFeed.categories = updatedSelection;
    sourceFeed.categories.sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, categories: updatedSelection };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  };

  function handleFormatTypeIDChange(event: ChangeEvent<HTMLSelectElement>): void {
    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, formatTypeID: parseInt(event.target.value) };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  }

  function handleRegionTypeIDChange(event: ChangeEvent<HTMLSelectElement>): void {
    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, regionTypeID: parseInt(event.target.value) };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  }

  function handleLanguageCodeIDChange(event: ChangeEvent<HTMLSelectElement>): void {
    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, languageCodeID: parseInt(event.target.value) };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  }

  const handleCrawlWinMinChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const timeValue = event.target.value; // format: "HH:MM"
    const [hours, minutes] = timeValue.split(':').map(Number);

    let localDate = new Date();
    localDate.setHours(hours);
    localDate.setMinutes(minutes);

    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, crawlWindowMin: localDate };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  };

  function handleCrawlWinMaxChange(event: ChangeEvent<HTMLInputElement>): void {
    const timeValue = event.target.value; // format: "HH:MM"
    const [hours, minutes] = timeValue.split(':').map(Number);

    let localDate = new Date();
    localDate.setHours(hours);
    localDate.setMinutes(minutes);

    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, crawlWindowMax: localDate };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  }

  function handleCrawlIntervalChange(event: ChangeEvent<HTMLSelectElement>): void {
    setSourceFeed((prev) => {
      const updatedFeed: SourceFeed = { ...prev, crawlInterval: parseInt(event.target.value) };
      handleInputChange(updatedFeed);
      return updatedFeed;
    });
  }

  //#endregion

  return (
    <div className=" h-full">
      {/*LoadingSpinner takes place of content when data is being fetched*/}
      {loading ? ( // Render loading spinner if data is loading
        <div className="flex items-center justify-center h-full">
          <LoadingSpinner size="h-12" />
        </div>
      ) : (
        <div className="px-4 sm:gap-4 sm:px-0">
          <div
            className="h-full pb-8 pt-4 "
            aria-hidden="true"
          >
            {/*START - SourceFeed details content */}
            <div className="mb-4">
              <label className="block sm:text-lg text-md font-bold leading-6 text-foreground">Name</label>
              <div className="pt-2">
                <input
                  type="text"
                  name="sourcefeedName"
                  id="sourcefeedName"
                  value={sourceFeed.name}
                  onChange={handleNameChange}
                  className="block w-full rounded-md border-0 p-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-base text-sm sm:leading-6"
                  placeholder="Source feed name"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="url"
                className="block sm:text-lg text-md  font-bold leading-6 text-foreground"
              >
                Domain
              </label>
              <div className="pt-2">
                <input
                  type="url"
                  name="domain"
                  id="sourcefeedDomain"
                  value={sourceFeed.sourceDomain}
                  onChange={handlesourceDomainChange}
                  className="block w-full rounded-md border-0 p-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-base text-sm sm:leading-6"
                  placeholder="Source feed domain URL"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block sm:text-lg text-md  font-bold leading-6 text-foreground"
              >
                Url
              </label>
              <div className="pt-2">
                <input
                  type="url"
                  name="url"
                  id="sourcefeedUrl"
                  value={sourceFeed.locationURL}
                  onChange={handlelocationURLChange}
                  className="block w-full rounded-md border-0 p-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-base text-sm sm:leading-6"
                  placeholder="Source feed URL"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="formatType"
                className=" sm:text-lg text-md  font-bold leading-6 text-foreground"
              >
                Format Type
              </label>
              <div className="pt-2">
                {' '}
                <select
                  id="formatType"
                  name="formatType"
                  value={sourceFeed.formatTypeID}
                  onChange={handleFormatTypeIDChange}
                  className=" block w-32 sm:w-40 rounded-md border-0 p-1.5 ring-secondary text-foreground ring-1 ring-inset bg-background/50  focus:ring-1 focus:ring-tertiary sm:text-base text-sm sm:leading-6"
                >
                  <option value={1}>RssXml</option>
                  <option value={2}>Atom</option>
                  <option value={3}>JSON</option>
                </select>
              </div>
            </div>
          </div>

          <div className="py-8 border-t border-tertiary">
            <div className="grid grid-cols-2">
              <div>
                <label
                  htmlFor="regionType"
                  className="sm:text-lg text-md  font-bold leading-6 text-foreground"
                >
                  Region Type
                </label>
                <div className="pt-2">
                  <select
                    id="regionType"
                    name="regionType"
                    autoComplete="region-type"
                    value={sourceFeed.regionTypeID}
                    onChange={handleRegionTypeIDChange}
                    className="pt-2 block w-32 sm:w-40 rounded-md border-0 p-1.5  text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary sm:text-base text-sm sm:leading-6"
                  >
                    <option value={1}>Universal</option>
                    <option value={2}>Global</option>
                    <option value={3}>National</option>
                    <option value={4}>Regional</option>
                    <option value={5}>State</option>
                    <option value={6}>County</option>
                    <option value={7}>City</option>
                    <option value={8}>Hyperlocal</option>
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="regionType"
                  className="sm:text-lg text-md  font-bold leading-6 text-foreground"
                >
                  Location
                </label>
                <div className="pt-2">
                  <select
                    id="location"
                    name="location"
                    autoComplete="location"
                    className="pt-2 block w-32 sm:w-40 rounded-md border-0 p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary sm:text-base text-sm sm:leading-6"
                  >
                    <option>TO DO</option>
                  </select>
                </div>
              </div>
              <div className="pt-6">
                <label
                  htmlFor="formatType"
                  className="sm:text-lg text-md  font-bold leading-6 text-foreground"
                >
                  Language
                </label>
                <div className="pt-2">
                  <select
                    id="language"
                    name="language"
                    autoComplete="English"
                    value={sourceFeed.languageCodeID}
                    onChange={handleLanguageCodeIDChange}
                    className="pt-2 block w-32 sm:w-40 rounded-md border-0 p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary sm:text-base text-sm sm:leading-6"
                  >
                    <option value={1}>English</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="py-8  border-t border-tertiary">
            <div>
              <div>
                <div className="sm:text-lg text-md  font-bold leading-6 text-foreground">Last Sync Time</div>
                <div className="pt-2 leading-6 text-foreground">{sourceFeed.lastSyncTime.toLocaleString()}</div>
              </div>
              <div className="grid grid-cols-2 ">
                <div>
                  <div className="pt-6">
                    <label
                      htmlFor="regionType"
                      className="text-md font-bold leading-6 text-foreground"
                    >
                      Crawl Window Min
                    </label>
                    <div className="pt-2">
                      <input
                        aria-label="Time"
                        type="time"
                        defaultValue={
                          sourceFeed.crawlWindowMin
                            ? `${sourceFeed.crawlWindowMin.getHours().toString().padStart(2, '0')}:${sourceFeed.crawlWindowMin.getMinutes().toString().padStart(2, '0')}`
                            : ''
                        }
                        onChange={handleCrawlWinMinChange}
                        className="pt-2 block w-30 sm:w-30 rounded-md border-0 p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary sm:text-base text-sm  sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="pt-6">
                    <label
                      htmlFor="regionType"
                      className="sm:text-lg text-md  font-bold leading-6 text-foreground"
                    >
                      Crawl Interval
                    </label>
                    <div className="pt-2">
                      {' '}
                      <select
                        id="crawlInterval"
                        name="crawlInterval"
                        autoComplete="crawl-interval"
                        onChange={handleCrawlIntervalChange}
                        value={sourceFeed.crawlInterval}
                        className="pt-2 block w-32 sm:w-40 sm:w-30 rounded-md border-0 p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary sm:text-base text-sm sm:leading-6"
                      >
                        <option value={30}>30 mins</option>
                        <option value={60}>1 hour</option>
                        <option value={120}>2 hours</option>
                        <option value={240}>4 hours</option>
                        <option value={360}>6 hours</option>
                        <option value={480}>8 hours</option>
                        <option value={720}>12 hours</option>
                        <option value={1440}>24 hours</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="pt-6">
                    <label
                      htmlFor="regionType"
                      className="sm:text-lg text-md  font-bold leading-6 text-foreground"
                    >
                      Crawl Window Max
                    </label>

                    <div className="pt-2">
                      <input
                        aria-label="Time"
                        type="time"
                        defaultValue={
                          sourceFeed.crawlWindowMax
                            ? `${sourceFeed.crawlWindowMax.getHours().toString().padStart(2, '0')}:${sourceFeed.crawlWindowMax.getMinutes().toString().padStart(2, '0')}`
                            : ''
                        }
                        onChange={handleCrawlWinMaxChange}
                        className="pt-2 block w-30 sm:w-30 rounded-md border-0 p-1.5 text-foreground ring-1 ring-inset bg-background/50 ring-secondary focus:ring-1 focus:ring-tertiary sm:text-base text-sm  sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Categories START */}
          <div className="px-4 py-8  sm:gap-4 sm:px-0 border-t border-tertiary">
            <div className="sm:text-lg text-md  font-bold leading-6 text-foreground">Categories</div>
            <MultiselectList
              dbList={dbCategories}
              preAssignedList={sourceFeed.categories}
              onSelectionChange={handleSelectionChange}
            />
          </div>
          {/* Categories END */}

          <div className="py-8 border-t border-tertiary">
            <div className="grid grid-cols-2">
              <div>
                <div className="sm:text-lg text-md   font-bold leading-6 text-foreground">Updated</div>
                <div className="pt-2 leading-6 text-foreground sm:text-base text-sm">
                  {sourceFeed.lastUpdated.toLocaleString()}
                </div>
                <div className="pt-4 font-bold sm:text-lg text-md  leading-6 text-foreground">Author</div>
                <div className="pt-2 leading-6 text-foreground sm:text-base text-sm">
                  {sourceFeed.author?.firstName || 'Unknown'} {sourceFeed.author?.lastName || 'Unknown'}
                </div>
              </div>
              <div>
                <div className="sm:text-lg text-md  font-bold leading-6 text-foreground">Created</div>
                <div className="pt-2 leading-6 text-foreground sm:text-base text-sm">
                  {sourceFeed.dateCreated
                    .toLocaleString(undefined, {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true, // Use 12-hour format
                    })
                    .replace(',', ', ')}
                </div>
                <div className="pt-4  sm:text-lg text-md  font-bold leading-6 text-foreground">Organization</div>
                <div className="pt-2 leading-6 text-foreground sm:text-base text-sm">{sourceFeed.organizationName}</div>
              </div>
            </div>
          </div>

          {/*End - SourceFeed details content */}
        </div>
      )}
    </div>
  );
}
